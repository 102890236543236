<template>
  <div class="addScenery">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{
        id=="undefined"?"添加店铺" :"编辑店铺"
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="100px" class="form_box">
      <el-form-item label="店铺排序">
        <el-input
          v-model="formList.storeSort"
          placeholder="请输入店铺排序"
        ></el-input>
      </el-form-item>
      <el-form-item label="店铺名称">
        <el-input
          v-model="formList.storeName"
          placeholder="请输入店铺名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="店铺封面">
        <send-image
          @addImageStr="addImageStr"
          :images="formList.storeHomeImg"
          v-if="
            Number(id)==0||(Number(id) > 0&&formList.storeName!=='')
          "
        ></send-image>

        <span style="color:red; margin-left:20px"> 建议尺寸168*168px </span>
      </el-form-item>
      <el-form-item label="设为自提点">
        <el-radio-group
          v-model="formList.isDefault"
          style="height:35px; display:flex; align-items:center"
        >
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="店铺电话">
        <el-input
          v-model="formList.storeTel"
          placeholder="请输入店铺服务热线"
        ></el-input>
      </el-form-item>
      <el-form-item label="标签" class="tag_box">
        <el-tag
          :key="tag"
          v-for="tag in formList.storeTag"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          style="width:200px"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput"
          >+ 添加标签</el-button
        >
      </el-form-item>

      <el-form-item label="地址" class="map">
        <!-- <my-map
          @onAddressChange="onAddressChangeHandle"
          :Address1="formList.storeAddr"
          :lon="formList.storeLon"
          @getAddress="getAddress"
          :lat1="formList.storeLat"
        ></my-map> -->
         <my-map
              @selectAddress="onAddressChange"
              @onMapClick="onMapClick"
              :Address1="formList.storeAddr"
              :pos="[formList.storeLon,formList.storeLat,]"
            ></my-map>
      </el-form-item>
      <el-form-item label="经度" class="col">
        <el-input v-model="formList.storeLon"></el-input>
      </el-form-item>
      <el-form-item label="纬度" class="col">
        <el-input v-model="formList.storeLat"></el-input>
      </el-form-item>
      <div style="margin:1.5rem 0 0 6rem">
        <el-button @click="$router.go(-1)">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>

import myMap from "../../components/map.vue";
import { sendFile } from "../../api/sendFile";
import quillConfig from "../../../quill-config";
import { shopEdit } from "../../api/selectMall";
import { addScenery } from "../../api/sceneryList";
import SendImage from "../../components/sendImage.vue";
export default {
  name:"addScenery",
  components:{
    myMap:myMap,
    SendImage,
  },
  props:{
    id:{
      type:[Number, String],
      required:true,
    },
  },
  data() {
    return {
      quillOption:quillConfig,
      inputVisible:false,
      inputValue:"",
      // provinceValue:"",
      // cityValue:"",
      // areaValue:"",
      // streetValue:"",
      // provinceList:[],
      // cityList:[],
      // cityList:[],
      // areaList:[],
      // streetList:[],
      formList:{
        storeTag:[],
        storeName:"",
        storeHomeImg:"",
        storeTel:"",
        storeAddr:"",
        storeLon:113.324553,
        storeLat:23.106414,
        isDefault:1,
      },
    };
  },
  watch:{
    provinceValue:async function () {
      const { data } = await getCityByProvince({
        provinceCode:this.provinceValue,
      });
      this.cityList = data.data;
    },
    cityValue:async function () {
      const { data } = await getAreaByCity({
        cityCode:this.cityValue,
      });
      this.areaList = data.data;
    },
    areaValue:async function () {
      const { data } = await getStreetByArea({
        areaCode:this.areaValue,
      });
      this.streetList = data.data;
    },
  },
  created() {
    // this.getAllProvinceList();
  },
  mounted() {
    if (this.id&&this.id!=="0") {
      let formList = { ...this.$store.state.mallShopData };
      formList.storeTag = formList.storeTag.split(",");
      this.formList = formList;
      console.log(formList,'formList')
    }
  },
  methods:{
    getAddress(address){
      console.log(address,'addressaddressaddress')
      this.formList.storeAddr = address
    },
    async onSave() {
      console.log(this.formList)
      for (const key in this.formList) {
        if (this.formList[key]===null||this.formList[key]==="") {
          return this.$message({
            type:"error",
            message:"请把数据填写完整",
          });
        }
      }
      let formData = { ...this.formList };
      formData.storeTag = formData.storeTag.join(",");
      const { data } = await shopEdit(formData);
      if (data.code==0) {
        this.$router.go(-1);
        this.$message({
          type:"success",
          message:data.msg,
        });
      } else {
        this.$message({
          type:"error",
          message:data.msg,
        });
      }
    },
    // 获取所有省份列表
    async getAllProvinceList() {
      const { data } = await getAllProvince();
      this.provinceList = data.data;
    },
       onMapClick({address,lat,lon}){
      this.formList.address = address
      this.formList.storeLat = lat
      this.formList.storeLon = lon

    },
    formatDEfaultAddress(){
      // 获取选择默认地址
       const pro = this.provinceList.filter(item=>{
       return item.code == this.provinceValue
      })
       const city= this.cityList.filter(item2=>{
       return item2.code == this.cityValue
      })
      const area = this.areaList.filter(item3=>{
       return item3.code == this.areaValue
      })
      const street = this.streetList.filter(item4=>{
       return item4.code == this.formList.streetCode
      })
      let addressArr = [...pro,...city,...area,...street]
      let defaultArr = []
     addressArr.forEach(item5=>{
      defaultArr.push(item5.name)
     })
     this.formList.address =  defaultArr.join('')
     console.log( defaultArr.join(''),'defaultAddress')
    },
   onAddressChange({pname,cityname,adname,address,location}) {
    this.formList.storeAddr= ""
        const { lat,lng} = location
      console.log(address, lat,lng);
      this.formList.storeAddr = pname+cityname+adname+address;
      this.formList.storeLon = lng;
      this.formList.storeLat = lat;
    },
    addImageStr(image) {
      this.formList.storeHomeImg = image;
    },
    async onFileChange(event) {
      // 根据这个 <input> 获取文件的 HTML5 js 对象
      var files = event.target.files;
      if (files&&files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        this.formList.storeHomeImg = data.data[0].src;
      }
    },
    onClose() {
      this.formList.storeHomeImg = "";
    },
    onSendImg() {
      this.$refs.fileRefs.click();
    },
    onSendImg1() {
      this.$refs.fileRefs1.click();
    },
    handleClick(tab, event) {},

    /**
     * 标签相关
     */
    // 删除标签
    handleClose(tag) {
      this.formList.storeTag.splice(this.formList.storeTag.indexOf(tag), 1);
    },
    // 显示input
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.focus();
      });
    },
    // 确认保存标签
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.formList.storeTag.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>
<style lang="less" scoped>
.addScenery {
  .el-breadcrumb {
    height:35px;
    border-bottom:1px solid rgb(192, 191, 191);
  }
  .form_box {
    margin-top:40px;
  }
  /deep/ .el-form {
    .el-form-item__content {
    }
    .map {
      /deep/ .el-form-item__content {
        height:420px;
        .container {
          height:360px;
          width:710px;
          .search-box {
            #searchTip {
              width:710px !important;
              .amap-ui-poi-picker-sugg {
                .amap-ui-poi-picker-sugg-list {
                  width:710px !important;
                }
              }
            }
          }
        }
      }
    }
    /deep/ .col {
      display:inline-block;
      .el-form-item__content {
        width:300px;
        .el-input {
          width:305px;
        }
      }
    }
    .street {
      .el-form-item__content {
        .el-select {
          width:175px;
        }
      }
    }
    .el-form-item__content {
      .el-select {
        width:350px;
      }
      .el-input {
        width:700px;
      }
    }
  }
  .el-tag {
    margin:5px 20px 2px 0;
  }
}
</style>